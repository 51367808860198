import { Link } from "react-router-dom";
import { localStorageKey } from "../../api";
import { useContext } from "react";
import { AppContext } from "../../AppRouter";
import { jwtDecode } from "jwt-decode";
import "./nav.css";


export function Nav() {
  async function proceedToLogout() {
    localStorage.removeItem(localStorageKey);
    window.location.reload();
  }

  const context = useContext(AppContext);
  const username = context.user.username;
  // checking admin rights
  const notAdmin = username !== "admin";
  // checking ReNGen access
  let token = localStorage.getItem('psa-x-token') || "";
  if (token === "None") {
    token = "";
  } 
  let isLoggedIn = false;
  let hasAccess = false;
  if (token) {
    const decodedToken = jwtDecode(token) as any;
    hasAccess = decodedToken["ReNGen_access"] === 1 ? true : false;
    isLoggedIn = true;
  }

  return (
    <div className="nav-bar">
      <img src="/ps-logo.svg" className="nav-bar-logo" alt="Punter Southall Analytics Logo" />
      <div className="nav-bar-links">
        {hasAccess && (
        <Link className="nav-link" to={`/research-note-builder`}>
          ReNGen
        </Link>
        )}
        {isLoggedIn && (
        <Link className="nav-link" to={`/horizon-scanner`}>
          Horizon Scanner
        </Link>
        )}
        {!notAdmin && (
          <Link className="nav-link" to={`/admin`}>
            Admin
          </Link>
        )}
        {isLoggedIn && (
        <button className="logout-nav" onClick={proceedToLogout}>
          Log out
        </button>
        )}
      </div>
    </div>
  );
}
