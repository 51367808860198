import Input from "../../components/input/Input";
import { useState, FormEvent } from "react";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import "./LoginPage.css";
import { login } from "../../api";
import { Link } from "react-router-dom";

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    try {
      const respCode = await login(email, password);

      switch (respCode) {
        case 0:
          window.location.reload();
          break;
        case 1:
          console.log("Token not found in response");
          setError(true);
          break;
        case 2:
          setError(true);
          break;
      }

    } catch (error) {
      console.log("Error in login")
      console.error(error);
      setError(true);
    }

    setLoading(false);
  }

  return (
    <div className="content login-content">
      <div className="login-header">
        <h1>AI Portal</h1>
        <img src="/ps-logo.svg" className="login-logo" alt="Punter Southall Analytics Logo" />
      </div>

      <form onSubmit={handleSubmit}>
        <Input labelText="Username" id="Username" type="text" value={email} onChange={setEmail} />
        <Input labelText="Password" id="Password" type="password" value={password} onChange={setPassword} />
        {!!error && <p>Incorrect user name or password.</p>}
        <div className="form-submitter">
          <button type="submit" className="button" style={{ width: "100px" }} disabled={!email || !password || !!loading}>
            {!loading && "Submit"}
            {!!loading && <LoadingSpinner className="login-loader" />}
          </button>
          <Link className="create-account-link" to={`/sign-up`}>Create account</Link>
        </div>
      </form>
    </div>
  );
}
