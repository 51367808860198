import ListInputComponent from "../list-input/ListInput";
import "./Url.css";

interface Props {
  urls: string[];
  setUrls: (url: string[]) => void;
}

const UrlRenComponent: React.FC<Props> = ({ urls, setUrls }) => {

  return (
    <div className="url-form-whole-container">
      {/* <h3>Enter 1 or more URLs</h3> */}
      <ListInputComponent values={urls} setValues={setUrls} isUrlInput={true} />
    </div>
  );
};

export default UrlRenComponent;
