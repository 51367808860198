import { ChangeEvent } from "react";
import "./FileUpload.css";

interface Props {
  files: File[];
  setFiles: (files: File[]) => void;
}

const FileUploadComponent: React.FC<Props> = ({ setFiles, files }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    const file = event.target.files[0];
    setFiles([...files, file]);
  };

  const functionToHandleDeletingAValue = (valueToDelete: File) => {
    const updatedFiles = files.filter((file) => file.name !== valueToDelete.name);
    setFiles(updatedFiles);
  };

  return (
    <div className="file-upload-holder">
      <ul className="existing-value">
        {files.map((file, index) => (
          <li key={index} className="added-content-and-delete-button">
            <div className="added-content">{file.name}</div>
            <button type="button" className="delete-button" onClick={() => functionToHandleDeletingAValue(file)}>
              -
            </button>
          </li>
        ))}
      </ul>
      <label htmlFor="file-upload" className="file-upload-button">
        Click here to upload file
      </label>
      <input style={{ display: "none" }} className="choose-file-input" type="file" name="file-upload" id="file-upload" onChange={handleFileChange} />
    </div>
  );
};

export default FileUploadComponent;
