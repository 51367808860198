import Input from "../../components/input/Input";
import { FormEvent, useState } from "react";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { signup } from "../../api";
import "./SignUpPage.css";
import { Link } from 'react-router-dom';

export function SignUpPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [createUserSuccess, setCreateUserSuccess] = useState(false);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    setErrorMsg("");
    try {
      const responseCode = await signup(email, password, pin);
      switch (responseCode) {
        case 0:
          // navigate to signin page
          // navigate('/');
          setCreateUserSuccess(true);
          break;
        case 1:
          setErrorMsg("Token not found in response.");
          break;
        case 2:
          setErrorMsg("Username already exists.");
          break;
        case 3:
          setErrorMsg("Invalid Pin.");
          break;
        case 4:
          console.log(responseCode);
          setErrorMsg("Unknown error in signup.");
          break;
        default:
          console.log(responseCode);
          setErrorMsg("Unknown error in signup.");
      }
      
      // window.location.reload();
    } catch (error) {
      console.error(error);
      setErrorMsg("An error occurred. Please try again.");
    }

    setLoading(false);
  }

  return (
    <div className="content signup-content">
      <div className="signup-header">
        <h1>AI Portal</h1>
        <img src="/ps-logo.svg" className="login-logo" alt="Punter Southall Analytics Logo" />
      </div>

      <form onSubmit={handleSubmit}>
        <Input labelText="Username" id="Email" type="text" value={email} onChange={setEmail} />
        <Input labelText="Password" id="password" type="password" value={password} onChange={setPassword} />
        <Input labelText="Pin" id="pin" type="passwordq" value={pin} onChange={setPin} />
        {errorMsg && <p className="error-message">{errorMsg}</p>}
        {createUserSuccess && <p className="success-message">User created successfully. Click here to log in: <Link className="create-account-link" to={`/`}>Log in</Link>.</p>}
        <div className="form-submitter">
          <button type="submit" className="button" style={{ width: "100px" }} disabled={!email || !password || !!loading || createUserSuccess}>
            {!loading && "Submit"}
            {!!loading && <LoadingSpinner className="signup-loader" />}
          </button>
        </div>
      </form>
    </div>
  );
}
