import { FormEvent, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Input from "../../components/input/Input";
// import { sendSelectedParagraphToEmail, postUrlAndEmail, postTextAndEmail, postFileAndEmail, postAllData } from "../../api"; 
import { sendSelectedParagraphToEmail, postAllData } from "../../api"; // original line above
import LoadingButton from "../../components/loading-button/LoadingButton";
import ScanForMe from "../../components/ren-components/ScanForMe";
import UrlRenComponent from "../../components/ren-components/Url";
import TextComponent from "../../components/ren-components/Text";
import FileUploadComponent from "../../components/ren-components/FileUpload";
import { isEmailValid } from "../../utils";
import Modal from "../../components/modal/Modal";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import "./ResearcherNotBuilderPage.css";
import ResearchNoteBuilderHelp from '../../components/help/ResearchNoteBuilderHelp';

export function ResearchNoteBuilderPage() {

  // first decide if users have access to this page.
  const navigation = useNavigate();
  const token = localStorage.getItem('psa-x-token') || "";
  let hasAccess = false;
  if (token) {
    const decodedToken = jwtDecode(token) as any;
    hasAccess = decodedToken["ReNGen_access"] === 1 ? true : false;
  }
  // if not, reroute to home page.
  if (!hasAccess) {
    navigation('/')
  }

  // rest of function:

  // type UseButton = "ScanForMe" | "Upload" | "URL" | "Text" | "Multi";
  type UseButton = "ScanForMe" | "Multi";

  const [email, setEmail] = useState("");
  const [buildingResponse, setBuildingResponse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectParagraph, setSelectParagraph] = useState("");
  const [whichButtonToUse, setWhichButtonToUse] = useState<UseButton>("ScanForMe");
  const [urls, setUrls] = useState<string[]>([]);
  const [text, setText] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [modalStatus, setModalStatus] = useState(false);

  async function scanForMeButton() {
    setWhichButtonToUse("ScanForMe");
  }

  // async function uploadButton() {
  //   setWhichButtonToUse("Upload");
  // }

  // async function urlButton() {
  //   setWhichButtonToUse("URL");
  // }

  // async function textButton() {
  //   setWhichButtonToUse("Text");
  // }

  async function multiButton() {
    setWhichButtonToUse("Multi");
  }

  async function sendEmailForRen(event: FormEvent) {
    setLoading(true);
    event.preventDefault();

    if (whichButtonToUse === "ScanForMe") {
      const no = selectParagraph;
      const scanForMenformationToPost = { no, email };
      await sendSelectedParagraphToEmail(scanForMenformationToPost);
    // } else if (whichButtonToUse === "URL") {
    //   await postUrlAndEmail({ urls, email });
    // } else if (whichButtonToUse === "Text") {
    //   const textInformationToPost = { text, email };
    //   await postTextAndEmail(textInformationToPost);
    // } else if (whichButtonToUse === "Upload") {
    //   if (!files || files.length === 0) {
    //     return;
    //   }
    //   const emailAndFile = { files, email };
    //   await postFileAndEmail(emailAndFile);
    } else if (whichButtonToUse === "Multi") {
      await postAllData({ files, urls, text, email });
    }

    setBuildingResponse(true);
    setLoading(false);
  }

  async function openModal() {
    setModalStatus(true);
  }

  function isBuildButtonDisabled() {
    if (!email || !isEmailValid(email)) {
      return true;
    }

    // if (whichButtonToUse === "Upload" && (!files || files.length === 0)) {
    //   return true;
    // }

    // if (whichButtonToUse === "ScanForMe" && !selectParagraph) {
    //   return true;
    // }

    // if (whichButtonToUse === "URL" && urls.length === 0) {
    //   return true;
    // }

    if (whichButtonToUse === "Multi" && (!files || files.length === 0) && urls.length === 0 && !text) {
      return true;
    }

    return false;
  }

  async function startProcessAgain() {
    setEmail("");
    setBuildingResponse(false);
    setSelectParagraph("");
    setUrls([]);
    setText("");
    setFiles([]);
    setModalStatus(false);
  }

  // When whichButtonToUse changes, set buildingResponse to false
  useEffect(() => {
    setBuildingResponse(false);
  }, [whichButtonToUse]);

  return (
    <div className="research-page-content">
      <Modal isOpen={modalStatus} handleClose={() => setModalStatus(false)} modalStyles={{ maxWidth: "1000px" }}>
        <ResearchNoteBuilderHelp />
      </Modal>
      <div className="title-and-modal">
        <h1 className="page-title">ReNGen</h1>
        <button onClick={openModal} className="modal-button">
          <InformationCircleIcon />
        </button>
      </div>
      <div className="scanforme-uploadurl-text-bar">
        <button className={`scan-me-button ${whichButtonToUse === "ScanForMe" ? "active" : ""}`} style={{fontSize: "15px"}} onClick={scanForMeButton}>
          Scan for me
        </button>
        {/* <button className={`upload-button ${whichButtonToUse === "Upload" ? "active" : ""}`} onClick={uploadButton}>
          Upload
        </button>
        <button className={`url-button ${whichButtonToUse === "URL" ? "active" : ""}`} onClick={urlButton}>
          URL
        </button>
        <button className={`text-button ${whichButtonToUse === "Text" ? "active" : ""}`} onClick={textButton}>
          Text
        </button> */}
        <button className={`text-button ${whichButtonToUse === "Multi" ? "active" : ""}`} style={{fontSize: "15px"}} onClick={multiButton}>
          Input my sources
        </button>
      </div>


      {/* // tp: trying to change the front end functionality to accept several upload methods. */}
      <div className="redner-the-selected-component-option">
        {whichButtonToUse === "ScanForMe" && (
        <ScanForMe selectedParagraph={selectParagraph} setSelectedParagraph={setSelectParagraph}
        />
        )}
      </div>
      {(whichButtonToUse !== "ScanForMe" || !!selectParagraph) && (
        <form onSubmit={sendEmailForRen} className="email-input-and-build-button">
          {/* {whichButtonToUse === "URL" && <UrlRenComponent urls={urls} setUrls={setUrls} />}
          {whichButtonToUse === "Text" && <TextComponent text={text} setText={setText} />}
          {whichButtonToUse === "Upload" && <FileUploadComponent files={files} setFiles={setFiles} />} */}
          {whichButtonToUse === "Multi" && (
            <>
              <h3> Enter URLs</h3>
              <UrlRenComponent urls={urls} setUrls={setUrls} />
              <h3> Upload Files</h3>
              <FileUploadComponent files={files} setFiles={setFiles} />
              <h3> Enter Text</h3>
              <TextComponent text={text} setText={setText} />
            </>
          )}
          <Input labelText="Email" id="Email" type="email" value={email} onChange={setEmail} invalid={!isEmailValid(email)} />
          <LoadingButton loading={loading} text="Build Report" type="submit" disabled={isBuildButtonDisabled()} />
        </form>
)}
      {!!buildingResponse && (
        <div className="building-response-container">
          <p>Request successful! You will receive an email with the report shortly</p>
          <LoadingButton loading={loading} text="Start again" onClick={startProcessAgain} />
        </div>
      )}
    </div>
  );
}
