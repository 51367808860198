import Input from "../input/Input";

interface Props {
  text: string;
  setText: (text: string) => void;
}


const TextComponent: React.FC<Props> = ({ text, setText }) => {
  return (
    <div className="text-form-whole-container">
      <Input 
        id="Text" 
        value={text} 
        onChange={setText} 
        type="textarea"
        inputStyle={{resize: "none", overflowY: "auto", height: "150px"}}
      />
    </div>
  );
};

export default TextComponent;
