export const isValidURL = (url: string): boolean => {
  // Regular expression for validating URLs
  const urlRegex: RegExp = /^(https?):\/\/(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/i;

  // Test the provided URL against the regular expression
  return urlRegex.test(url);
};

export function isEmailValid(email: string): boolean {
  // Regular expression for validating email addresses
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the regular expression
  return emailRegex.test(email);
}
