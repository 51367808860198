import { FormEvent, useState } from "react";
import Input from "../input/Input";
import Markdown from "markdown-to-jsx";
import LoadingButton from "../loading-button/LoadingButton";
import { sendTermsForScan } from "../../api";
import ListInputComponent from "../list-input/ListInput";
import "./ScanForMe.css";

interface Props {
  selectedParagraph: string;
  setSelectedParagraph: (selectedParagraph: string) => void;
}

const ScanForMe: React.FC<Props> = ({ selectedParagraph, setSelectedParagraph }) => {
  const [loading, setLoading] = useState(false);
  const [scanResults, setScanresults] = useState<string[]>([]);
  const [scanIndex, setScanIndex] = useState(0);
  const [terms, setTerms] = useState<string[]>([]);
  const [WhiteListSites, setWhiteListSites] = useState<string[]>([]);

  async function submitTheScanTermsForm(event: FormEvent) {
    setLoading(true);
    setScanresults([]);
    event.preventDefault();
    const responseFromTermScan = await sendTermsForScan(WhiteListSites, terms);
    setScanresults(responseFromTermScan.answer);
    setLoading(false);
  }

  const showScanResults = !!scanResults.length;
  const amountOfScanResults = scanResults.length;
  const theScan = scanResults;
  const theIndex = scanIndex;

  async function showPreviousScanIndex() {
    if (scanIndex > 0) {
      setScanIndex(scanIndex - 1);
    } else {
      setScanIndex(scanIndex);
    }
  }

  async function showNextScanIndex() {
    const maxScanIndex = amountOfScanResults - 1;
    if (scanIndex < maxScanIndex) {
      setScanIndex(scanIndex + 1);
    } else {
      setScanIndex(0);
    }
  }

  async function selectTheParagraph() {
    setSelectedParagraph(theScan[theIndex].slice(2));
  }

  return (
    <div className="terms-form-whole-container">
      <form onSubmit={submitTheScanTermsForm}>
        <div className="terms-and-sites-input-container">
          <div className="term-input-holder">
            <h3>Enter up to 4 terms</h3>
            <ListInputComponent values={terms} setValues={setTerms} isUrlInput={false}/>
          </div>
          <div className="whitelist-input-holder">
            <h3>Whitelist sites</h3>
            <ListInputComponent values={WhiteListSites} setValues={setWhiteListSites} isUrlInput={true} />
          </div>
        </div>

        <LoadingButton loading={loading} text="Scan" type="submit" />
      </form>
      {!!showScanResults && (
        <div>
          <div className="scan-results-and-select-scroller">
            <button onClick={showPreviousScanIndex} className="scan-results-previous-button">
              Previous
            </button>
            <div className="scan-results-content-holder">
              <Markdown
                options={{
                  overrides: {
                    a: {
                      props: {
                        target: "_blank",
                      },
                    },
                  },
                }}
              >
                {theScan[theIndex].slice(2)}
              </Markdown>
            </div>
            <button onClick={showNextScanIndex} className="scan-results-next-button">
              Next
            </button>
          </div>
          <button onClick={selectTheParagraph} className="select-paragraph-button">
            Select paragraph
          </button>
        </div>
      )}
      {!!selectedParagraph && (
        <div className="scan-results-email-options">
          <Input labelText="Selected paragraph" id="Selected paragraph" type="textarea" value={selectedParagraph} onChange={setSelectedParagraph} />
        </div>
      )}
    </div>
  );
};

export default ScanForMe;
