import { useState, FormEvent, useEffect, useRef } from "react";
import Input from "../../components/input/Input";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import "./HorizonScannerPage.css";
import {
  AdvancedSearchInputInformation,
  askBasicQuestion,
  askForInternetSearch,
  sendAdvancedSearchInputsToBackend,
  downloadHorizonScanner,
  startAgainHorizonScanner,
  produceReportFromHorizonChats,
  askDataAnalystChat,
  // getHorizonScannerUploadDate
} from "../../api";
import Markdown from "markdown-to-jsx";
import Modal from "../../components/modal/Modal";
import TagSelect from "../../components/tag-select/TagSelect";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import HorizonScannerHelp from '../../components/help/HorizonScannerHelp';

export type Message = {
  text: string;
  type: "AI" | "user";
};

type ChatMode = "BASIC" | "ADVANCED" | "DATA";

export function HorizonScannerPage() {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chatMode, setChatMode] = useState<ChatMode>("BASIC");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [advancedSheet, setAdvancedSheet] = useState<string[]>([]);
  const [advancedJurisdiction, setAdvancedJurisdiction] = useState<string[]>([]);
  const [modalStatus, setModalStatus] = useState(false);
  // const [lastUploadDate, setLastUploadDate] = useState("");

  const listRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (listRef.current) {
      const scrollHeight = listRef.current.scrollHeight;
      listRef.current.scrollTo({ behavior: "smooth", top: scrollHeight });
    }
  };

    // // Get the date of the last upload
    // useEffect(() => {
    //   async function getLastUploadDate() {
    //     const date = await getHorizonScannerUploadDate();
    //     setLastUploadDate(date);
    //   }
  
    //   getLastUploadDate();
    // }, []);
      
  async function handleQuestionSendRequest(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    setMessages((messages) => [
      ...messages,
      {
        type: "user",
        text: question,
      },
    ]);
    try {
      console.log(chatMode);
      if (chatMode === "BASIC") {
        setQuestion("");
        const latestResponse = await askBasicQuestion(question);
        setMessages((messages) => [
          ...messages,
          {
            type: "AI",
            text: latestResponse.answer,
          },
        ]);
        setQuestion("");
      } else if (chatMode === "ADVANCED") {
        setQuestion("");
        const sheet_names = advancedSheet;
        const date_from = dateFrom.slice(0, 10);
        const date_to = dateTo.slice(0, 10);
        const Jurisdiction = advancedJurisdiction;

        const advancedDataToSend: AdvancedSearchInputInformation = {
          question,
          sheet_names,
          date_from,
          date_to,
          Jurisdiction,
        };

        const latestResponse = await sendAdvancedSearchInputsToBackend(advancedDataToSend);
        setMessages((messages) => [
          ...messages,
          {
            type: "AI",
            text: latestResponse.answer,
          },
        ]);
        setQuestion("");
      } else if (chatMode === "DATA") {
        setQuestion("");
        const sheet_names = advancedSheet;
        const date_from = dateFrom.slice(0, 10);
        const date_to = dateTo.slice(0, 10);
        const Jurisdiction = advancedJurisdiction;

        const advancedDataToSend: AdvancedSearchInputInformation = {
          question,
          sheet_names,
          date_from,
          date_to,
          Jurisdiction,
        };

        const latestResponse = await askDataAnalystChat(advancedDataToSend);
        setMessages((messages) => [
          ...messages,
          {
            type: "AI",
            text: latestResponse.answer,
          },
        ]);
        setQuestion("");
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    scrollToBottom();
  }, [loading]);

  const handleInternetSearchClick = async () => {
    setLoading(true);
    setMessages((messages) => {
      return [
        ...messages,
        {
          type: "user",
          text: "Please search the internet for more information",
        },
      ];
    });

    try {
      const internetSearchResponse = await askForInternetSearch();
      setMessages((messages) => {
        return [
          ...messages,
          {
            type: "AI",
            text: internetSearchResponse.answer,
          },
        ];
      });
    } catch (error) {
      console.error(error);
      setError(true);
    }

    setLoading(false);
  };

  const displayAdditionalOptions = !loading && messages.find((message) => message.type === "AI");

  async function basicButton() {
    setChatMode("BASIC");
  }

  async function advancedButton() {
    setChatMode("ADVANCED");
  }

  async function dataButton() {
    setChatMode("DATA");
  }

  const displayAdvancedOptions = chatMode === "ADVANCED" || chatMode === "DATA";
  const displayBasicOptions = chatMode === "BASIC";

  async function downloadTheHorizonScanner() {
    const downloadData = await downloadHorizonScanner();
    const downloadUrl = window.URL.createObjectURL(downloadData);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "HorizonScanner.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async function createHorizonScannerReport() {
    const downloadData = await produceReportFromHorizonChats();
    const downloadUrl = window.URL.createObjectURL(downloadData);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "HorizonScannerReport.docx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async function startAgainButton() {
    await startAgainHorizonScanner();
    setMessages([]);
  }

  async function openModal() {
    setModalStatus(true);
  }

  return (
    <div className="content">
      <Modal isOpen={modalStatus} handleClose={() => setModalStatus(false)} modalStyles={{ maxWidth: "1000px" }}>
        <HorizonScannerHelp />
      </Modal>
      <div className="head-of-page">
        <h1 className="page-title">Horizon Scanner Q&A</h1>

        <button onClick={openModal} className="modal-button">
          <InformationCircleIcon />
        </button>
      </div>

      {/* <div style={{ textAlign: 'right' }}>
          Date of last upload: {lastUploadDate || "loading..."} 
      </div> */}

      <div className="page-content">
        <div className="basic-advanced-download-button-bar">
          <button onClick={basicButton} className={`basic-chat-button ${chatMode === "BASIC" ? "active" : ""}`}>
            Specific chat
          </button>
          <button onClick={advancedButton} className={`advanced-chat-button ${chatMode === "ADVANCED" ? "active" : ""}`}>
            Filtered chat
          </button>
          <button onClick={dataButton} className={`data-chat-button ${chatMode === "DATA" ? "active" : ""}`}>
            Analytics chat
          </button>
          <button onClick={downloadTheHorizonScanner} className="download-horizon-scanner-button">
            Download Horizon Scanner
          </button>
        </div>

        <div className="middle-excluding-button-bar">
          <div className="messages-div" ref={listRef}>
            <ul className="sent-messages-holder">
              {messages.map((messages, index) => {
                return (
                  <li key={`${messages.text}_${index}`} className={`message message--${messages.type}`}>
                    <Markdown
                      options={{
                        overrides: {
                          a: {
                            props: {
                              target: "_blank",
                            },
                          },
                        },
                      }}
                    >
                      {messages.text}
                    </Markdown>
                  </li>
                );
              })}
              {!!displayAdditionalOptions && (
                <div className="additional-option-holder">
                  <button onClick={handleInternetSearchClick} className="message message--additional-option">
                    Internet search
                  </button>
                  <button onClick={createHorizonScannerReport} className="message message--additional-option-two">
                    Produce report
                  </button>
                </div>
              )}
              {!!loading && (
                <li className="message message--loader">
                  <LoadingSpinner className="message-loader" />
                </li>
              )}
              {!!error && <p>Error encountered, please try again.</p>}
            </ul>
          </div>

          <div className="advanced-or-basic-chat-holder">
            {!!displayBasicOptions && <div className="display-basic-options-holder">Switch to filtered or analytics chat for additional options.</div>}
            {!!displayAdvancedOptions && (
              <div className="display-advanced-options-holder">
                <TagSelect
                  availableTags={[
                    "Dawn Raids",
                    "UK Behavioural Investigations",
                    "UK super-complaints",
                    "Competition Cases",
                    "FCA, PRA & CBI Letters",
                    "Stichting",
                    "UK Collective Proceedings",
                    "Actuarial Risk Alerts",
                  ]}
                  selectedTags={advancedSheet}
                  onChange={setAdvancedSheet}
                  labelText="Sheet"
                />
                <Input id="date-from" labelText="From" type="date" value={dateFrom} onChange={setDateFrom} />
                <Input id="date-to" labelText="To" type="date" value={dateTo} onChange={setDateTo} />
                <TagSelect availableTags={["UK", "EU & EEA"]} selectedTags={advancedJurisdiction} onChange={setAdvancedJurisdiction} labelText="Jurisdiction" />
              </div>
            )}
          </div>
        </div>

        <form className="horizon-form" onSubmit={handleQuestionSendRequest}>
          <Input
            labelText="Enter your question here..."
            id=""
            value={question}
            onChange={setQuestion}
            type="text"
            inputClassName="question-input"
            containerClassName="question-input-container"
          />
          <button type="submit" className="button" style={{ width: "80px" }} disabled={!question || !!loading}>
            Send
          </button>
          <button className="start-again" type="button" onClick={startAgainButton}>
            Start again
          </button>
        </form>
      </div>
    </div>
  );
}
