import { ButtonHTMLAttributes } from "react";
import "./LoadingButton.css";

interface Props {
  loading: boolean;
  text?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: () => void;
  disabled?: boolean;
}

const LoadingButton: React.FC<Props> = ({ loading, text, type, onClick, disabled }) => {
  return (
    <button className="button" type={type} onClick={onClick} disabled={disabled}>
      {!!loading && (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="loading-button-svg">
          <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
          <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
          <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
        </svg>
      )}
      {!loading && text}
    </button>
  );
};

export default LoadingButton;
