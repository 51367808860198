import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { HomePage } from "./pages/home-page/HomePage";
import { HorizonScannerPage } from "./pages/horizon-scanner-page/HorizonScannerPage";
import { ResearchNoteBuilderPage } from "./pages/research-note-builder-page/ResearcherNoteBuilderPage";
import { Nav } from "./components/nav/nav";
import { AdminPage } from "./pages/admin-page/AdminPage";

export type User = {
  username: string;
};

export type UserAppContext = {
  user: User;
};
const initialContext: UserAppContext = {
  user: {
    username: "",
  },
};

export const AppContext = createContext<UserAppContext>(initialContext);

type Props = {
  user: User;
};

const AppRouter: React.FC<Props> = ({ user }) => {
  const context: UserAppContext = {
    user,
  };

  return (
    <AppContext.Provider value={context}>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/horizon-scanner" element={<HorizonScannerPage />} />
          <Route path="/research-note-builder" element={<ResearchNoteBuilderPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default AppRouter;
