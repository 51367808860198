import { Fragment } from 'react';

interface Props {}

const HorizonScannerHelp: React.FC<Props> = () => {
  return (
    <Fragment>
      <h1 id="horizon-scanner-q-a">Horizon Scanner Q&amp;A</h1>
      <p>
        Welcome to the PSA Horizon Scanner Q&amp;A Chat. 
      </p>
      <p>
        There are three types of chat you can engage with our artificial
        intelligence. You should select the correct one to get the best
        results.{' '}
      </p>
      <h2 id="specific-chat">Specific Chat</h2>
      <p>
        This chat should be used if you know which case (or cases) you are 
        looking for information on. It is fast and offers back and forth chat 
        functionality. You should try asking your question in the specific chat 
        before using any of the others.{' '}
      </p>
      <p>
      For instance, this chat would be perfect to ask the question:
      </p>
        <blockquote>
          “Tell me about the CMA’s investigation into Rangers Football club 
          regarding their replica football kits.”.{' '}
      </blockquote>
      <p>
        It also does have limited capacity to answer slightly broader
        questions, such as:
        </p>
      <blockquote>
        “Tell me about any mentions of Deutsche Bank”
      </blockquote>
      <p>You may ask follow-up questions as you would with a human. </p>
      <h2 id="filtered-chat">Filtered Chat</h2>
      <p>
        This chat should be used if you know which section of the Horizon 
        Scanner your information belongs in, and/or if you want to restrict your
        question to certain date ranges or jurisdictions. This is a more
        powerful chat which can answer broader questions. It does, however, 
        take more time to retrieve the information.
      </p>
      <p> 
        This chat could be used to ask:
      </p>
      <blockquote>
        “Which authority conducted the most dawn raids in 2019?”
        (Selecting the 'Dawn Raids' sheet and the date range 01/01/2019 –
        31/12/2019)
      </blockquote>
      <blockquote>
        “Please summarise how often behaviour in each sector is investigated by
        UK regulators.” (Selecting the 'UK Behavioural Investigations' sheet)
      </blockquote>
      <p>You can also ask follow-up questions as you would with a human. </p>
      <h2 id="analytics-chat">Analytics Chat</h2>
      <p>
        This chat should be used if you want to do any kind of number analysis. 
        It works best when you apply filters (the same as you do on the filtered 
        chat). 
      </p>
      <p>
        This chat plans, writes and executes code behind the scenes to answer
        your questions, so can take some time to give a response. 
      </p>
      <p>
        Some example questions could be:
      </p>
      <blockquote>
        "Can you tell me what the median average time is between a dawn raid and
        a press release?"
      </blockquote>
      <blockquote>
        "What’s the average number of Dear CEO letters published by the FCA in a
        year?"
      </blockquote>
      <p>
        This chat does not allow for follow up questions, but you can switch to
        the other chats if you want to ask further questions.
      </p>
      <h2 id="other">Other</h2>
      <p>
        <strong>Important.</strong> If you start chatting about a new
        unrelated topic. You must click the “start again” button.
        You may click on the “Download Horizon Scanner” button to download
        the current horizon scanner excel spreadsheet.
      </p>
      <p>
        If you’d like to browse the Horizon Scanner in its more familiar form,
        you can click on the “Download horizon scanner” button to download the
        current Excel spreadsheet. 
      </p>
      <p>
        We will aim to update the source on the first Monday of each month.
        Please be aware that the Chat will therefore not be functional during 
        the morning on this day while we make necessary updates.
      </p>
    </Fragment>
  );
};

export default HorizonScannerHelp;
