import Axios from "axios";

export const localStorageKey = "psa-x-token";
const jwtToken = localStorage.getItem(localStorageKey);

const axios = Axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL || "https://psa-ai-portal-backend.azurewebsites.net/api",
  // baseURL: "http://localhost:5001/api/",
  headers: {
    "x-access-tokens": jwtToken || undefined,
  },
});

type LoginResponse = {
  message: string;
  token: string;
  ReNGen_access: string;
};

export const login = async (username: string, password: string) => {
  const formData = new FormData();

  formData.set("username", username);
  formData.set("password", password);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const res = await axios.post<LoginResponse>("/login", formData, { headers });

  if (res.data.message === "log in successful") {

    if (res.data.token) {
      localStorage.setItem(localStorageKey, res.data.token);
      return 0;
    } else {
      console.log("Token not found in response");
      return 1;
    };
  } else {
    return 2;
  }
  
  // shouldn't reach here
  // return 3;
};

type GetUserResponse = {
  username: string;
  message?: string;
};

export const getUser = async () => {
  const res = await axios.get<GetUserResponse>("/get_user");

  if (res.data.message === "token is invalid") {
    throw new Error(res.data.message);
  }

  return res.data;
};

type BasicQuestionResponse = {
  answer: string;
};

type DataQuestionResponse = {
  answer: string;
};

export type AdvancedSearchInputInformation = {
  question: string;
  sheet_names: string[];
  date_from: string;
  date_to: string;
  Jurisdiction: string[];
};

type ScanTermResponse = {
  answer: string[];
};

type EmailSelectedParagraphResponse = {
  answer: string;
};

type EmailSelectedParagraph = {
  no: string;
  email: string;
};

type EmailAndUrl = {
  urls: string[];
  email: string;
};

export type EmailAndText = {
  text: string;
  email: string;
};

export type EmailAndFiles = {
  files: File[];
  email: string;
};

export type Terms = {
  search_terms: string[];
};

export type WhiteListSites = {
  extra_white_list_sites: string[];
};

type SignupResponse = {
  message: string;
  token: string;
};

export type HorizonScannerFile = {};

export const askBasicQuestion = async (question: string) => {
  const res = await axios.post<BasicQuestionResponse>("/HS_qa_basic", {
    question,
  });
  return res.data;
};

export const askForMoreDetail = async () => {
  const res = await axios.post<BasicQuestionResponse>("/more_detail");
  return res.data;
};

export const askForInternetSearch = async () => {
  const res = await axios.post<BasicQuestionResponse>("/QA_IS");
  return res.data;
};

export const sendAdvancedSearchInputsToBackend = async (advancedDataToSend: AdvancedSearchInputInformation) => {
  const res = await axios.post<BasicQuestionResponse>("/HS_qa_advanced", advancedDataToSend);
  return res.data;
};

export const downloadHorizonScanner = async () => {
  const res = await axios.post("/download_HS", undefined, {
    responseType: "blob",
  });
  return res.data;
};

export const sendTermsForScan = async (extra_white_list_sites: string[], search_terms: string[]) => {
  const res = await axios.post<ScanTermResponse>("/scan", {
    search_terms,
    extra_white_list_sites,
  });
  return res.data;
};

export const sendSelectedParagraphToEmail = async (emailSelectedParagraph: EmailSelectedParagraph) => {
  const res = await axios.post<EmailSelectedParagraphResponse>("/deep_reports_no", emailSelectedParagraph);
  return res.data;
};

export const postUrlAndEmail = async (emailAndUrl: EmailAndUrl) => {
  const res = await axios.post("/deep_reports_url", emailAndUrl);
  return res.data;
};

export const postTextAndEmail = async (emailAndText: EmailAndText) => {
  const res = await axios.post("/deep_reports_text", emailAndText);
  return res.data;
};

export const postFileAndEmail = async (emailAndFiles: EmailAndFiles) => {
  const formData = new FormData();
  formData.set("email", emailAndFiles.email);
  emailAndFiles.files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  const res = await axios.post("/deep_reports_up", formData);
  return res.data;
};

// tp: adding function to handle sending multiple input types for ReNGen
export const postAllData = async (data: { files?: File[], urls?: string[], text?: string, email: string }) => {
  const formData = new FormData();

  if (data.files) {
    data.files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });
  }
  
  if (data.urls && data.urls.length > 0) {
    formData.append('urls', JSON.stringify(data.urls));
  }
  
  if (data.text) {
    formData.append('text', data.text);
  }
  
  if (data.email) {
    formData.append('email', data.email);
  }

  const res = await axios.post("/deep_reports_multi", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
};


export const startAgainHorizonScanner = async () => {
  const res = await axios.post("/start_again");
  return res.data;
};

export const produceReportFromHorizonChats = async () => {
  const res = await axios.post("/produce_report", undefined, {
    responseType: "blob",
  });
  return res.data;
};

export const signup = async (username: string, password: string, pin: string) => {
  const formData = new FormData();

  formData.set("username", username);
  formData.set("password", password);
  formData.set("pin", pin);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const res = await axios.post<SignupResponse>("/new_user", formData, {
    headers,
  });

  const message = res.data.message;

  switch (message) {
    case "new user created successfully":
      if (res.data.token) {
        localStorage.setItem(localStorageKey, res.data.token);
        return 0;
      } else {
        console.log("Token not found in response");
        return 1;
      }
    case "Username already exists":
      return 2;
    case "Incorrect Pin":
      return 3;
    default:
      console.log(res.data);
      return 4;
  }
};

export const uploadAdminsHorizonScanner = async (file: File) => {
  const formData = new FormData();

  formData.set("horizon_scanner_file", file);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const res = await axios.post("/update_hs", formData, { headers });
  return res.data;
};

export const uploadAdminsHorizonScannerStatus = async () => {
  const res = await axios.get("/update_hs_status");
  return res.data.progress;
};

export const getHorizonScannerUploadDate = async () => {
   const res = await axios.get("/get_hs_last_upload_date");
   return res.data.last_update_date;
};

export const downloadUsageStatistics = async () => {
  const res = await axios.post("/get_usage", undefined, {
    responseType: "blob",
  });
  return res.data;
};

export const askDataAnalystChat = async (advancedDataToSend: AdvancedSearchInputInformation) => {
  const res = await axios.post<DataQuestionResponse>("/HS_qa_analytics", advancedDataToSend);
  return res.data;
};
