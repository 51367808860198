import { BrowserRouter, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Nav } from "./components/nav/nav";
import { SignUpPage } from "./pages/sign-up-page/SignUpPage";
import { LoginPage } from "./pages/login-page/LoginPage";

interface SignedOutRouterProps {
  isLoggedIn: boolean;
}

const RedirectIfNotLoggedIn: React.FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && location.pathname !== "/sign-up") {
      navigate("/");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return null;
};

const SignedOutRouter: React.FC<SignedOutRouterProps> = ({ isLoggedIn }) => {
  return (
    <BrowserRouter>
      <RedirectIfNotLoggedIn isLoggedIn={isLoggedIn} />
      <Nav />
      <Routes>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default SignedOutRouter;
