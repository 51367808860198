import { TrashIcon } from "@heroicons/react/24/outline";
import SearchableSelect from "react-select";
import "./TagSelect.css";
import { Fragment } from "react";

interface Props {
  availableTags: string[];
  selectedTags: string[];
  onChange: (tags: string[]) => void;
  labelText: string;
  helpText?: string;
  readOnly?: boolean;
}

const TagSelect: React.FC<Props> = ({ availableTags, selectedTags, onChange, labelText, helpText, readOnly }) => {
  const handleDeleteTag = (tagToDelete: string) => {
    onChange(selectedTags.filter((selectedTag) => selectedTag !== tagToDelete));
  };

  const handleAddOwnerGroup = (tagToAdd: string) => {
    onChange([...selectedTags, tagToAdd]);
  };

  return (
    <div className="tag-select__container">
      <p className="tag-select__label">{labelText}</p>
      <div className="tag-select__selected-tags">
        {selectedTags.map((tag) => {
          return (
            <div key={tag} className="tag-select__selected-tag">
              <span>{tag}</span>
              <button type="button" className="link-button" onClick={() => handleDeleteTag(tag)}>
                <TrashIcon />
              </button>
            </div>
          );
        })}
      </div>
      {!readOnly && (
        <Fragment>
          <SearchableSelect
            id="select-owner-group"
            value={null}
            options={availableTags.map((tag) => ({ value: tag, label: tag })).filter((tag) => !selectedTags.includes(tag.value))}
            onChange={(value) => handleAddOwnerGroup(value?.value as string)}
          />
          {!!helpText && <small>{helpText}</small>}
        </Fragment>
      )}
    </div>
  );
};

export default TagSelect;
