import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export function HomePage() {

  // checking ReNGen access
  let token = localStorage.getItem('psa-x-token') || "";
  if (token === "None") {
    token = "";
  } 
  let hasAccess = false;
  if (token) {
    const decodedToken = jwtDecode(token) as any;
    hasAccess = decodedToken["ReNGen_access"] === 1 ? true : false;
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (hasAccess) {
      navigate("/research-note-builder");
    } else {
      navigate("/horizon-scanner");
    }
  }, [hasAccess, navigate]);

  return (
    <div>
      {/* <h1>Home</h1> */}
    </div>
  );
}
