import { Fragment } from 'react';

interface Props {}

const ResearchNoteBuilderHelp: React.FC<Props> = () => {
  return (
    <Fragment>
      <h1 id="research-note-generator-rengen-">Research Note Generator (ReNGen)</h1>
      <p>Welcome to the PSA research note generator. </p>
      <p>
        <strong>Important.</strong> If you have not been told you have access to this resource it is
        likely you will not be able to produce reports or conduct scans. Please contact your PSA
        contact if you wish to gain access.
      </p>
      <p>
        This is a tool which uses state of the art Large Language Models (LLMs) and sophisticated AI
        engineering to conduct up-to-date research about potential breaches in rules regulations and
        laws. These reports are highly in-depth but should be checked for accuracy.
      </p>
      <h2 id="scan-for-me">Scan for me</h2>
      <p>
        If you don’t have a specific news story you are interested in, then you can allow the AI to
        search many trusted websites for the most recent interesting news. You can do this on the{' '}
        <strong>Scan for me</strong> tab. You can enter up to four terms such as “Customer harm”,
        “Consumer detriment” or “Competition issues” etc. You may also add extra websites to the
        trusted sources via the Whitelist sites option. This will take 15-30 minutes to conduct a
        scan. Once the scan is complete, select the story of interest, enter you email, and click{' '}
        <strong>Build REN.</strong> This can take up to an hour to complete, and the report will be
        emailed directly to you.{' '}
      </p>
      <h2 id="upload-url-and-text">Upload, URL and Text</h2>
      <p>
        If you have a document, and website or just want to type exactly the case of interest, then
        use these tabs, enter your email address and hit <strong>Build REN.</strong> The email will
        be emailed to you in roughly an hour.
      </p>
    </Fragment>
  );
};

export default ResearchNoteBuilderHelp;
