import { HTMLInputTypeAttribute } from "react";
import styles from "./Input.module.css";
import DatePicker from "react-datepicker";

export interface InputProps {
  labelText?: string;
  helpText?: string;
  id: string;
  type?: HTMLInputTypeAttribute | "textarea" | "date";
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  small?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  placeHolder?: string;
  disable?: boolean;
  invalid?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  labelText,
  id,
  type,
  value,
  onChange,
  helpText,
  readOnly,
  small,
  inputClassName,
  inputStyle,
  containerClassName,
  disable,
  invalid,
  onKeyPress
}) => {
  let readOnlyValue = value;
  if (type === "date" && value) {
    readOnlyValue = new Date(value).toLocaleDateString();
  }

  const nonStandardInputTypes = ["textarea", "date"];
  const renderInvalidStyle = invalid && !!value;
  return (
    <div
      className={`${styles["input__container"]} ${styles[`input__container--${small ? "small" : type}`]} ${containerClassName || ""} ${renderInvalidStyle ? styles["input__container--invalid"] : ""}`}
    >
      <label className={styles["input__label"]} htmlFor={id}>
        {labelText}
      </label>
      {!readOnly && !nonStandardInputTypes.includes(type || "") && (
        <input
          type={type || "text"}
          className={`${styles["input"]} ${inputClassName || ""}`}
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(type === "checkbox" ? e.target.checked.toString() : e.target.value)}
          checked={type === "checkbox" ? value === "true" : undefined}
          style={inputStyle}
          placeholder={id}
          disabled={disable}
          onKeyPress={onKeyPress}
        />
      )}
      {!readOnly && type === "textarea" && (
        <textarea
          className={`${styles["input"]} ${inputClassName || ""}`}
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={inputStyle}
        />
      )}
      {!readOnly && type === "date" && (
        <DatePicker
          id={id}
          className={`${styles["input"]} ${inputClassName || ""}`}
          dateFormat="dd/MM/yyyy"
          selected={value ? new Date(value) : undefined}
          onChange={(date) => onChange(date?.toISOString() || "")}
        />
      )}
      {readOnly && (
        <p className={`${styles["input"]} ${styles["input--readonly"]} ${inputClassName || ""}`} style={inputStyle}>
          {readOnlyValue}
        </p>
      )}
      {!!helpText && <small className={styles["input__help-text"]}>{helpText}</small>}
    </div>
  );
};

export default Input;
