import React from "react";
import ReactDOM from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import AppRouter from "./AppRouter";
import reportWebVitals from "./reportWebVitals";
import { getUser } from "./api";
import SignedOutRouter from "./SignedOutRouter";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// render a loading message
root.render(
  <React.StrictMode>
    <p>Loading...</p>
  </React.StrictMode>,
);

// try to get the logged in user
getUser()
  .then((res) => {
    console.log(res);
    // If it succeeds then render the app
    root.render(
      <React.StrictMode>
        <AppRouter
          user={{
            username: res.username,
          }}
        />
      </React.StrictMode>,
    );
  })
  .catch((error) => {
    console.log(error);
    // if it fails then render the login page
    root.render(
      <React.StrictMode>
        <SignedOutRouter isLoggedIn={false}/>
      </React.StrictMode>,
    );
  });

reportWebVitals();
