import FileUpload from "../../components/file-upload/FileUpload";
import Modal from "../../components/modal/Modal";
import { FormEvent, useContext, useEffect, useState} from "react";
// removed useRef, useCallback from the import above
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppRouter";
import { downloadUsageStatistics, uploadAdminsHorizonScanner} from "../../api";
// ^^ removed uploadAdminsHorizonScannerStatus from the import above
import "./AdminPage.css";
// import { clear, time } from "console";

function currentDate() {
  const date = new Date();
  return date.toLocaleDateString('en-UK', {
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
  });
}


export function AdminPage() {
  const [selectedFile, setSelectedFile] = useState<File>();
  // const [uploadProgress, setUploadProgress] = useState('');
  // const [uploading, setUploading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const navigate = useNavigate();
  const context = useContext(AppContext);

  async function adminPageBouncer() {
    const username = context.user.username;
    if (username !== "admin") {
      navigate(`/`);
    }
  }

  useEffect(() => {
    adminPageBouncer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function downloadUsageStats() {
    const downloadData = await downloadUsageStatistics();
    const downloadUrl = window.URL.createObjectURL(downloadData);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "UsageStats.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const handleUploadFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!selectedFile) {
      console.log("no file selected");
      return;
    }
    // setUploadProgress("0");
    // setUploading(true);
    setModalStatus(true);

    await uploadAdminsHorizonScanner(selectedFile);

    // setUploading(false);

    // if (timeoutId.current) {
    //   global.clearTimeout(timeoutId.current);
    // }

    // save the last upload date to local storage
    localStorage.setItem("lastHSUploadDate", currentDate());
  };

  // const timeoutId = useRef<NodeJS.Timeout | null>(null);

  // const getProgress = useCallback(async () => {
  //   if (!uploading){
  //     if (timeoutId.current !== null) {
  //       clearTimeout(timeoutId.current);
  //       timeoutId.current = null;
  //     }
  //     return;
  //   }

  //   let progress;

  //   try {
  //     progress = await uploadAdminsHorizonScannerStatus();
  //   } catch (e) {
  //     console.error("Error: ", e);
  //   }
    

  //   if(progress !== null) {
  //     setUploadProgress(progress);

  //     // console.log("**********************");
  //     console.log("uploading:", uploading)
  //     console.log("progress:", progress);
  //     // console.log("uploadProgress", uploadProgress)
  //     // console.log("**********************");

  //     // if (get === 1) {
  //     //   setUploadProgress(prevStatus => prevStatus + " Done.");
  //     //   clearTimeout(timeoutId);
  //     //   return;
  //     // }
  //   };

  // timeoutId.current = setTimeout(getProgress, 5000);
  // }, [uploading]);

  // useEffect(() => {
  //   if (uploading) {
  //     getProgress();
  //   }

  //   return () => {
  //     if (timeoutId.current) {
  //       global.clearTimeout(timeoutId.current);
  //       timeoutId.current = null;
  //     }
  //   };
  // }, [uploading, getProgress]);


  // const uploadProgress_ = getProgress();
  
  return (
    <div className="content">
      <h1>Admin</h1>

      <div className="upload-horizon-div">
        <h3>Upload horizon scanner</h3>
        <form className="upload-horizon-scanner-form" onSubmit={handleUploadFormSubmit}>
          <FileUpload setSelectedFile={setSelectedFile} />
          <button className="upload-horizon-button" disabled={!selectedFile}>
            Upload
          </button>
        </form>

      {modalStatus && 
      <Modal isOpen={modalStatus} 
      handleClose={() => setModalStatus(false)} 
      modalStyles={{ maxWidth: "600px", maxHeight: "400px", overflow: "auto"}}
      >
        <h2>File upload in progress...</h2> <br />
      {/*         
        {uploading === false ? 
          <>Upload Progess: 100%<br /><br />Done. You can now close this tab. <br /> Uploading: {uploading.toString()} </> : 
          <>Upload Progess: {Math.round(Number(uploadProgress) * 100)}% <br /><br /> Please do not close this tab. <br /> Uploading: {uploading.toString()}</> 
        } */}

      </Modal>}

      </div>

      <button className="download-stats-button" onClick={downloadUsageStats}>
        Download usage statistics
      </button>
    </div>
  );
}
