import Input from "../input/Input";
import { useState } from "react";
import styles from "./Input.module.css";
import "./ListInput.css";
import { isValidURL } from "../../utils";

interface Props {
  values: string[];
  setValues: (values: string[]) => void;
  isUrlInput?: boolean;
  className?: string;
  style?: string;
}


const ListInputComponent: React.FC<Props> = ({ values, setValues, isUrlInput, className, style }) => {
  const [newValue, setNewValue] = useState("");

  const disableInput = values.length >= 4;
  const [errorMessage, setErrorMessage] = useState("");

  async function functionToHandleAddingAValue(event: React.FormEvent) {
    event.preventDefault();
    if (!isValidURL(newValue) && isUrlInput) {
      setErrorMessage("Invalid URL - must start with 'http://' or 'https://'.");
    } else if (values.includes(newValue)) {
      setErrorMessage("Item already entered");
    } else {
      setValues([...values, newValue]);
      setNewValue("");
      setErrorMessage(""); // Clear the error message when a new value is added
    }
  }

  async function functionToHandleDeletingAValue(indexToDelete: number) {
    const updatedValues = values.filter((_, index) => index !== indexToDelete);
    setValues(updatedValues);
  }

  let disableAddButton = !newValue;
  let inputInvalid = false;
  if (isUrlInput) {
    disableAddButton = !isValidURL(newValue) || values.includes(newValue);
    inputInvalid = !isValidURL(newValue);
  }

  return (
    <div className={`${styles["list-input-holder"]}`}>
      <div className="content-without-buttons">
        <ul className="existing-value">
          {values.map((value, index) => (
            <li key={index} className="added-content-and-delete-button">
              <div className="added-content">{value}</div>
              <button type="button" className="delete-button" onClick={() => functionToHandleDeletingAValue(index)}>
                -
              </button>
            </li>
          ))}
        </ul>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={functionToHandleAddingAValue} className="add-content">
          <Input id="Enter" 
          value={newValue} 
          onChange={setNewValue} 
          disable={disableInput} 
          invalid={inputInvalid}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              functionToHandleAddingAValue(event);
            }
          }}
          
          />
          <button type="submit" className="add-button" onClick={functionToHandleAddingAValue} disabled={disableAddButton}>
            +
          </button>
        </form>
      </div>
    </div>
  );
};

export default ListInputComponent;
